<template>
  <div class="__calender">
    <div class="row">
      <div class="col-md-3">
        <div class="__title text-center">
          <p class="__">pick a date and time to schedule message</p>
        </div>
        <div class="__callender__component d-flex justify-content-center">
          <v-calendar
            v-model="date"
            :theme-styles="themeStyles"
            :locales="currentLocale">
            <button
              class="__nav_btns"
              slot="header-left-button"
              slot-scope="page"
              @click="page.movePrevMonth()">
              <img src="../../assets/arrow__forward.svg" alt="img" />
            </button>
            <button
              class="__nav_btns"
              slot="header-right-button"
              slot-scope="page"
              @click="page.moveNextMonth()">
              <img src="../../assets/__arrow__back.svg" alt="img" />
            </button>
          </v-calendar>
        </div>
        <div
          class="_display_message d-flex justify-content-center align-items-center my-4">
          <p class="__selected_text">{{ date }} at 1 : 00 am</p>
        </div>
        <div class="action d-flex justify-content-between">
          <button class="cancel" @cancel="cancel">Cancel</button>
          <el-button
            type="primary"
            @scheduleMessage="scheduleMessage"
            style="padding-top: 0.5em; padding-bottom: 0.5em"
            >Schedule Message</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "canlender",
    data() {
      return {
        date: "",
        themeStyles: {
          wrapper: {
            background: "linear-gradient(to bottom right, #ff5050, #ff66b3)",
            color: "#fafafa",
          },
        },
      };
    },
    methods: {
      cancel() {
        this.$emit("cancle");
      },
      scheduleMessage() {
        this.$emit("scheduleMessage");
      },
    },
    computed: {
      currentLocale() {
        return this.$store.getters["language/currentLanguage"];
      },
    },
  };
</script>

<style lang="scss">
  #calender {
    border: none;
    width: 330px;
  }
  .vc-arrows-container {
    padding: 20px !important;
  }
  .__top__text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    color: #484a4f;
    text-align: center;
    margin-top: 3em;
    margin-bottom: 2em;
  }
  .__nav_btns {
    width: 40.3px;
    height: 40.3px;
    background-color: #fff;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1.34346px 1.34346px rgba(0, 14, 51, 0.05);
    border-radius: 20.15px;
  }
  .__nav_btns:active {
    background: transparent;
  }
  .__calender {
    .__title p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 13px;
      color: #484a4f;
    }
    ._display_message {
      background-color: rgba(109, 34, 242, 0.1) !important;
      border-radius: 5px;
      height: 43px;
      .__selected_text {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #f7921c;
        margin: 0px;
        padding: 0px;
      }
    }
  }
</style>
