<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div
      class="modal-dialog modal-dialog-centered main-modal-content"
      role="document">
      <div class="modal-content">
        <div class="modal-header pt-4 pb-2">
          <h5 class="modal-title" id="exampleModalLabel">
            {{
              editUserProfile
                ? $t("dashboard.onboarding.update_information.edit_profile")
                : $t("dashboard.onboarding.update_information.title")
            }}
          </h5>
          <div class="d-flex">
            <h5 class="update-type">{{ editUserProfile ? "" : updateType }}</h5>
            <button class="close__modal" @click="closeX">X</button>
          </div>
        </div>
        <div class="modal-body m-0 p-0">
          <!-- INTITIAL UPDATE INFORMATION STATE -->
          <div
            class="row justify-content-center align-items-center modal-body-intro m-0 p-0"
            v-show="updateinformationState === 'initial'">
            <div class="col-md-10">
              <h3 class="main-info-text">
                {{ $t("dashboard.onboarding.update_information.what_use") }}
              </h3>
              <div class="d-flex justify-content-between mt-5">
                <button class="info-btn" @click="individualBtn">
                  {{ $t("dashboard.onboarding.update_information.individual") }}
                </button>
                <button class="info-btn" @click="cooperateBtn">
                  {{ $t("dashboard.onboarding.update_information.corporate") }}
                </button>
              </div>
            </div>
          </div>
          <!-- INDIVIDUAL ACCOUNTS CREATION -->
          <div
            class="row justify-content-center modal-body-intro mt-2"
            v-show="updateinformationState === 'individual'"
            style="">
            <div class="col-11">
              <el-form
                ref="ruleForm"
                :model="formData"
                :rules="rules"
                class="main-form"
                @submit.prevent.native="checkEnter">
                <div class="inner">
                  <div class="left-side-form">
                    <div class="row justify-content-center">
                      <div class="col-md-6">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.first_name.label'
                              )
                            "
                            prop="firstname">
                            <el-input
                              type="text"
                              autocomplete="off"
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.first_name.label'
                                )
                              "
                              v-model="formData.firstname"></el-input>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.last_name.label'
                              )
                            "
                            prop="lastname">
                            <el-input
                              type="text"
                              autocomplete="off"
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.last_name.label'
                                )
                              "
                              v-model="formData.lastname"></el-input>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <!-- Email -->
                      <div class="form_input_container contains-email">
                        <el-form-item label="Email" prop="email">
                          <el-input
                            type="text"
                            autocomplete="off"
                            placeholder="example@example.com"
                            v-model="formData.email"></el-input>
                        </el-form-item>
                      </div>
                      <!-- Email -->
                      <div class="col-md-12 my-0 py-0">
                        <el-form-item
                          style="margin: 0; padding: 0"
                          :label="
                            $t(
                              'dashboard.onboarding.update_information.form.birthday.label'
                            )
                          ">
                        </el-form-item>
                      </div>
                      <div class="col-md-12">
                        <div class="form_input_container mb-2">
                          <el-form-item
                            style="width: 100%; margin: 0; padding: 0"
                            prop="date_of_birth">
                            <el-date-picker
                              v-model="formData.date_of_birth"
                              type="date"
                              style="width: 100%"
                              format="yyyy-MM-dd"
                              placeholder="1999-02-05"
                              popper-class="notranslate">
                            </el-date-picker>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="right-side-form">
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.industry.label'
                              )
                            "
                            prop="industry_id">
                            <el-select
                              style="width: 100%"
                              v-model="formData.industry_id"
                              filterable
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.industry.placeholder'
                                )
                              ">
                              <el-option
                                v-for="(ind, key) in industries"
                                :key="key"
                                :label="`${ind.industry_name}`"
                                :value="ind.id"></el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form_input_container heard-main-container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.heard_about_us.label'
                              )
                            "
                            prop="heard_about_us"
                            class="heard-container">
                            <el-select
                              style="width: 100%"
                              v-model="formData.heard_about_us"
                              filterable
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.heard_about_us.placeholder'
                                )
                              ">
                              <el-option
                                v-for="(list, key) in listOfOptions"
                                :key="key"
                                :label="`${list.value}`"
                                :value="list.value"></el-option>
                            </el-select>
                            <!-- Insert option to input whatever here -->
                            <el-input
                              type="text"
                              autocomplete="off"
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.heard_about_us.custom'
                                )
                              "
                              v-model="formData.heard_about_us_other"
                              v-if="formData.heard_about_us === 'Other'"
                              class="other-sub-input"></el-input>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                    <span v-if="errors.length">
                      <Alert
                        v-for="(error, key) in errors"
                        :key="key"
                        :message="error[0]"
                        :type="type" />
                    </span>
                  </div>
                </div>
                <div class="bottom">
                  <div class="row justify-content-between mb-5">
                    <div class="col-md-12 d-flex justify-content-between pt-3">
                      <el-button
                        v-if="profileUpdated"
                        @click="closeModalBtn"
                        id="back">
                        {{ $t("misc.button.close") }}
                      </el-button>
                      <el-button v-else @click="back" id="back"
                        >{{ $t("misc.button.back") }}
                      </el-button>
                      <el-button
                        v-if="!editUserProfile"
                        id="continue"
                        :loading="loading"
                        type="primary"
                        @click="submitIndividualForm('ruleForm')"
                        >{{ $t("misc.button.save") }}</el-button
                      >
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
          <!-- CO-OPERATE ACCOUNT CREATION -->
          <div
            class="row justify-content-center modal-body-intro"
            v-show="updateinformationState === 'cooperate'">
            <div class="col-11">
              <el-form
                :model="formData"
                :rules="coperateFormRules"
                ref="coperatForm"
                class="main-form"
                @submit.prevent.native="checkEnter">
                <div class="inner">
                  <div class="left-side-form">
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.company_name.label'
                              )
                            "
                            prop="name">
                            <el-input
                              type="text"
                              autocomplete="off"
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.company_name.label'
                                )
                              "
                              v-model="formData.name"></el-input>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-md-12">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.company_email.label'
                              )
                            "
                            prop="email">
                            <el-input
                              type="text"
                              autocomplete="off"
                              placeholder="example@business.com"
                              v-model="formData.email"
                              :disabled="hasEmail"></el-input>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center">
                      <div class="col-md-12 my-0 py-0">
                        <el-form-item
                          style="margin: 0; padding: 0"
                          required
                          :label="
                            $t(
                              'dashboard.onboarding.update_information.form.date_of_establishment.label'
                            )
                          "></el-form-item>
                      </div>
                      <div class="col-md-12">
                        <div class="form_input_container mb-2">
                          <el-form-item
                            style="width: 100%; margin: 0; padding: 0"
                            prop="date_of_est">
                            <el-date-picker
                              v-model="formData.date_of_est"
                              type="date"
                              style="width: 100%"
                              format="yyyy-MM-dd"
                              placeholder="1999-02-05"
                              popper-class="notranslate">
                            </el-date-picker>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Left side end -->
                  <div class="right-side-form">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form_input_container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.industry.placeholder'
                              )
                            "
                            prop="industry_id">
                            <el-select
                              style="width: 100%"
                              v-model="formData.industry_id"
                              filterable
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.industry.placeholder'
                                )
                              ">
                              <el-option
                                v-for="(ind, key) in industries"
                                :key="key"
                                :label="`${ind.industry_name}`"
                                :value="ind.id">
                              </el-option>
                            </el-select>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form_input_container heard-main-container">
                          <el-form-item
                            :label="
                              $t(
                                'dashboard.onboarding.update_information.form.heard_about_us.label'
                              )
                            "
                            prop="heard_about_us">
                            <el-select
                              style="width: 100%"
                              v-model="formData.heard_about_us"
                              filterable
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.heard_about_us.placeholder'
                                )
                              "
                              class="heard-container">
                              <el-option
                                v-for="(list, key) in listOfOptions"
                                :key="key"
                                :label="`${list.value}`"
                                :value="list.value"></el-option>
                            </el-select>
                            <!-- Insert option to input whatever here -->
                            <el-input
                              type="text"
                              autocomplete="off"
                              :placeholder="
                                $t(
                                  'dashboard.onboarding.update_information.form.heard_about_us.custom'
                                )
                              "
                              v-model="formData.heard_about_us_other"
                              v-if="formData.heard_about_us === 'Other'"
                              class="other-sub-input"></el-input>
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <el-form-item
                          style="margin: 0; padding: 0"
                          :label="
                            $t(
                              'dashboard.onboarding.update_information.form.business_certificate.label'
                            )
                          ">
                        </el-form-item>
                        <div class="form_input_container">
                          <p class="business-descriptions">
                            {{
                              $t(
                                "dashboard.onboarding.update_information.form.business_certificate.description"
                              )
                            }}
                          </p>
                          <input
                            type="file"
                            accept=".pdf"
                            @change="handleFileChange"
                            ref="certificate"
                            id="fileUpload" />
                          <div class="fileUploadContainer" v-if="!imageUrl">
                            <button
                              :class="[fileError ? 'bordered' : '']"
                              id="fileUploadBtn"
                              @click.prevent="handleUploadFile"
                              class="upload-certificate">
                              {{
                                $t(
                                  "dashboard.onboarding.update_information.form.business_certificate.upload"
                                )
                              }}
                            </button>
                            <br />
                            <p style="color: #ff5656" v-if="fileError">
                              {{ fileError }}
                            </p>
                          </div>
                          <div class="fileUploadContainer" v-if="imageUrl">
                            <!-- <div class="pdf">
                                                            <pdf :src="imageUrl"></pdf>
                                                        </div> -->
                            <div class="progressContainer">
                              <button
                                @click="removeUploadedImage"
                                class="times">
                                X
                              </button>
                              <p
                                class="progressText"
                                style="color: #ff5656 !important">
                                {{
                                  $t(
                                    "dashboard.onboarding.update_information.form.business_certificate.remove"
                                  )
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Right side end -->
                <div class="row justify-content-between my-5">
                  <div class="col-md-12" v-if="errors.length">
                    <Alert
                      v-for="(error, key) in errors"
                      :key="key"
                      :message="error[0]"
                      :type="type" />
                  </div>
                  <div class="col-md-12 d-flex justify-content-between">
                    <el-button
                      v-if="profileUpdated"
                      @click="closeModalBtn"
                      id="back"
                      >{{ $t("misc.button.close") }}
                    </el-button>
                    <el-button v-else @click="back" id="back">{{
                      $t("misc.button.back")
                    }}</el-button>
                    <el-button
                      v-if="!editCorporateData"
                      :loading="loading"
                      @click="submitCoperatForm('coperatForm')"
                      id="continue"
                      type="primary"
                      >{{ $t("misc.button.save") }}
                    </el-button>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  import formatInputDate from "@/helpers/formatInputDate";
  import $ from "jquery";
  import togglerCorporateStatus from "@/helpers/toggleCorporateStatus.js";
  import corporate from "@/helpers/corporate";
  import currentUser from "@/helpers/currentUser";

  export default {
    name: "profile",
    data() {
      return {
        updateinformationState: "initial",
        profileUpdated: false,
        imageUrl: "",
        updateType: "",
        fileError: "",
        errors: [],
        percentage: 0,
        loading: false,
        showUploadedImage: false,
        showProgress: false,
        type: "alert-danger",
        hasEmail: false,
        colors: [
          { color: "#F7921C", percentage: 0 },
          { color: "#F7921C", percentage: 20 },
          { color: "#F7921C", percentage: 40 },
          { color: "#F7921C", percentage: 60 },
          { color: "#F7921C", percentage: 80 },
          { color: "#F7921C", percentage: 100 },
        ],
        listOfOptions: [
          {
            value: "Through A friend",
          },
          {
            value: "Social Media",
          },
          {
            value: "Linkedin",
          },
          {
            value: "Google",
          },
          {
            value: "Youtube",
          },
          {
            value: "Advertisement",
          },
          {
            value: "Other",
          },
        ],
        userDataForm: {},
        corporateData: {
          name: "",
          date_of_est: "",
          email: this.$store.state.auth.currentUser.email,
          phone: "",
          id: "",
          heard_about_us: "",
          industry_id: "",
          bus_cert: "",
        },
        userProfile: {},
        industries: [],
        pageLoading: false,
        userProfileData: false,
        editUserProfile: false,
        editCorporateData: false,
        formData: {
          firstname: "",
          lastname: "",
          name: "",
          heard_about_us: "",
          heard_about_us_other: "",
          email: this.$store.state.auth.currentUser.email,
          date_of_est: "",
          bus_cert: "",
          industry_id: "",
          bus_cert: "",
          date_of_birth: "",
        },
        userProfile: {
          email: "",
        },
        rules: {
          firstname: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          lastname: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          heard_about_us: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          industry_id: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          date_of_birth: [
            {
              required: false,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          email: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
              type: "email",
            },
          ],
        },
        coperateFormRules: {
          name: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          bus_cert: [
            {
              required: false,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          heard_about_us: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          industry_id: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
          date_of_est: [
            {
              required: true,
              message: this.$t("misc.form.required"),
              trigger: "change",
            },
          ],
        },
      };
    },
    computed: {
      checkProfileStatus() {},
      userAvatarLetters() {
        let firstLetter = currentUser().name
          ? currentUser().name.split(" ")[0].charAt(0).toUpperCase()
          : "";
        let secondLetter = currentUser().name
          ? currentUser().name.split(" ")[1].charAt(0).toUpperCase()
          : "";
        return `${firstLetter}${secondLetter}`;
      },
      getUsername() {
        if (!currentUser()) return "";
        return currentUser().email;
      },
      corporate() {
        return corporate();
      },
    },
    mounted() {
      store
        .dispatch("industries/getIndustryReporting")
        .then((res) => (this.industries = res));
      // if (this.$store.state.auth.currentUser.email.length > 0) {
      //     if (this.updateType == 'individual') {
      //         this.formData.email = this.$store.state.auth.currentUser.email
      //         this.hasEmail = true
      //     }
      //     else {
      //         this.corporateData.email = this.$store.state.auth.currentUser.email
      //         this.hasEmail = true
      //     }
      // }
      // store.dispatch('auth/viewIndividualProfile')
      //     .then(response => {
      //         if (response.data.data) {
      //             this.userProfile = { ...response.data.data }
      //         }
      //     })
      //   get user profile from the backend using vuex
      this.pageLoading = true;
    },
    methods: {
      closeX() {
        this.$emit("closeX");
      },
      back() {
        this.updateinformationState = "initial";
        this.loading = false;
        this.updateType = "";
        this.errors = [];
        this.formData = {
          firstname: "",
          lastname: "",
          day: "",
          month: "",
          year: "",
          name: "",
          heard_about_us: "",
          email: "",
          date_of_est: "",
          bus_cert: null,
          industry_id: "",
        };
        this.corporateData = {
          firstname: "",
          lastname: "",
          day: "",
          month: "",
          year: "",
          name: "",
          heard_about_us: "",
          date_of_est: "",
          bus_cert: null,
          industry_id: "",
        };
      },
      url() {
        return process.env.VUE_APP_ENGINE_URL;
      },
      showEditCorporateProfile() {
        this.formData = { ...this.corporateData };
        this.editCorporateData = true;
        this.cooperateBtn("Edit corporate information");
      },
      showEditIndividualProfile() {
        this.formData = { ...this.userProfile };
        this.editUserProfile = true;
        this.individualBtn();
      },
      removeUploadedImage(e) {
        this.showUploadedImage = false;
        this.formData.bus_cert = null;
        this.showProgress = false;
        this.imageUrl = "";
        this.fileError = "";
        e.preventDefault();
      },
      handleFileChange(event) {
        let file = this.$refs.certificate ? this.$refs.certificate.files : "";
        if (Object.keys(file).length !== 0) {
          let actualFile = file[0];
          this.formData.bus_cert = actualFile;
          this.showProgress = true;
          this.imageUrl = URL.createObjectURL(actualFile);
        }
      },
      handleUploadFile() {
        this.handleFileChange();
        document.getElementById("fileUpload").click();
        this.imageUrl = "";
      },
      individualBtn() {
        this.updateinformationState = "individual";
        this.updateType = "Personal account";
        if (this.$store.state.auth.currentUser.email.length > 0) {
          this.formData.email = this.$store.state.auth.currentUser.email;
          this.hasEmail = true;
        }
      },
      cooperateBtn() {
        this.updateinformationState = "cooperate";
        this.updateType = "Coporate account";
        if (this.$store.state.auth.currentUser.email.length > 0) {
          this.corporateData.email = this.$store.state.auth.currentUser.email;
          this.hasEmail = true;
        }
      },
      closeModalBtn() {
        this.updateType = "";
        this.updateinformationState = "initial";
        this.editUserProfile = false;
        $("#exampleModal").modal("hide");
      },
      submitIndividualForm(formName) {
        this.errors = [];
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let {
              date_of_birth,
              heard_about_us,
              firstname,
              lastname,
              industry_id,
              email,
            } = this.formData;
            let date =
              date_of_birth == "NaN-NaN-NaN"
                ? "Not Set"
                : formatInputDate(date_of_birth);
            // If heard about us is other, get the value from the other field
            heard_about_us =
              heard_about_us == "Other"
                ? this.formData.heard_about_us_other !== ""
                  ? this.formData.heard_about_us_other
                  : "Other"
                : heard_about_us;
            // let date = formatInputDate(date_of_birth)
            let payload = {
              firstname: firstname,
              lastname: lastname,
              heard_about_us: heard_about_us,
              date_of_birth: date,
              industry_id: industry_id,
              email: email,
              name: `${firstname} ${lastname}`,
            };
            store
              .dispatch("auth/submitIndividualProfile", payload)
              .then((response) => {
                this.loading = false;
                this.$notify({
                  title: "Success",
                  message: response.data.message,
                  type: "success",
                });
                this.$mixpanel.track("New User Profile", {
                  Name: `${firstname} ${lastname}`,
                  "Heard About Us": heard_about_us,
                  "Date of Birth": date,
                  Industry: industry_id,
                  Email: email,
                  Type: "Individual",
                });
                this.$mixpanel.people.set({
                  $name: `${firstname} ${lastname}`,
                  $email: email,
                });

                if (this.$store.getters["auth/currentUser"].country === "CI") {
                  this.$mixpanelFranco.track("New User Profile", {
                    Name: `${firstname} ${lastname}`,
                    "Heard About Us": heard_about_us,
                    "Date of Birth": date,
                    Industry: industry_id,
                    Email: email,
                    Type: "Individual",
                  });
                  this.$mixpanelFranco.people.set({
                    $name: `${firstname} ${lastname}`,
                    $email: email,
                  });
                }

                this.$emit("updateProfile");
                togglerCorporateStatus(false);
              })
              .catch((err) => {
                this.loading = false;
                if (err.response?.data == undefined) {
                  this.errors.push(["An error occured try again."]);
                }
                if (err.response && err.response.status === 404) {
                  this.errors = Object.values(err.response.data.data);
                }
              });
          } else {
            this.loading = false;
            return false;
          }
        });
      },
      submitCoperatForm(formName) {
        this.errors = [];
        this.loading = true;
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // if (!this.formData.bus_cert) {
            //   this.fileError = "Please upload business certificate.";
            // }
            let {
              date_of_est,
              heard_about_us,
              name,
              bus_cert,
              industry_id,
              email,
            } = this.formData;
            // Adjust heard about us here
            heard_about_us =
              heard_about_us == "Other"
                ? this.formData.heard_about_us_other !== ""
                  ? this.formData.heard_about_us_other
                  : "Other"
                : heard_about_us;
            let date = formatInputDate(date_of_est);
            //CREATE FORM DATA
            let formData = new FormData();
            formData.append("name", name);
            formData.append("date_of_est", date);
            formData.append("bus_cert", null);
            formData.append("heard_about_us", heard_about_us);
            formData.append("industry_id", industry_id);
            formData.append("email", email);

            store
              .dispatch("auth/submitCoperateProfile", formData)
              .then((response) => {
                this.loading = false;
                this.$notify({
                  title: "Success",
                  message: response.data.message,
                  type: "success",
                });
                this.$mixpanel.track("New User Profile", {
                  Name: name,
                  "Heard About Us": heard_about_us,
                  "Date of Birth": date,
                  Industry: industry_id,
                  Email: email,
                  Type: "Corporate",
                });
                if (this.$store.getters["auth/currentUser"].country === "CI") {
                  this.$mixpanelFranco.track("New User Profile", {
                    Name: name,
                    "Heard About Us": heard_about_us,
                    "Date of Birth": date,
                    Industry: industry_id,
                    Email: email,
                    Type: "Corporate",
                  });
                }
                togglerCorporateStatus(true);
                localStorage.setItem("userName", name);
                this.$emit("updateProfile");
              })
              .catch((err) => {
                this.loading = false;
                if (err.response?.data == undefined) {
                  this.errors.push(["An error occured try again."]);
                }
                if (err.response && err.response.status === 404) {
                  this.errors = Object.values(err.response.data.data);
                }
              });
          } else {
            this.loading = false;
            // if (!this.formData.bus_cert) {
            //   this.fileError = "Please upload business certificate.";
            // }
            return false;
          }
        });
      },
    },
  };
</script>
<style scoped lang="scss">
  .__profile__title {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 13px;
    color: #575761;
  }

  .__profile__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #575761;
  }

  #profile {
    min-height: 100vh;
    background-color: red;
  }

  .close__modal {
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: bolder;
    cursor: pointer;
    margin-left: 1em;
    padding-right: 0px;
  }

  .upload-information {
    width: 100%;
    padding-top: 1em;
    display: flex;
    justify-content: center;
    color: #000;
  }

  .profile-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;
  }

  .avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102.2px;
    height: 102.2px;
    background: #979797;
    opacity: 0.2;
    border-radius: 50%;
    margin-bottom: 0.5rem;
  }

  .avatar-text {
    font-weight: 600;
    font-size: 30px;
    line-height: 33px !important;
    color: #484a4f;
    margin-bottom: 0px !important;
  }

  .email p {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #575761;
  }

  #update-information {
    width: 100%;
    height: 40px;
    background: #f7921c;
    border-radius: 5px;
    color: #f6f6f6;
    outline: none;
    border: none;
    transition: all 0.5s;
  }

  #update-information:hover {
    outline: none !important;
  }

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .main-info-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #484a4f;
  }

  .info-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 167px;
    height: 40px;
    background: #f5f5f5;
    border-radius: 5px;
    outline: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #9e9ea7;
    border: none;
  }

  .info-btn:hover {
    background: #f7921c;
    color: #fff;
  }

  .modal-body-intro {
    min-height: 700px;
  }

  .__align__items {
    align-items: center;
  }

  .update-type {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    padding-top: 0.5em;
    color: #575761;
  }

  #back {
    width: 30%;
    border: 5px;
    height: 40px;
    background: #f5f5f5;
    border: none;
    outline: none;
    color: #9e9ea7;
  }

  #continue {
    width: fit-content;
    border: 5px;
    height: 40px;
  }

  .business-certificate {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #575761;
  }

  .business-descriptions {
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 140%;
    color: #484a4f;
  }

  .upload-certificate {
    height: 50px;
    width: 193px;
    background: #f6f6f6;
    border-radius: 5px;
    font-family: "Graphik Regular";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    color: #979797;
    outline: none;
    border: none;
  }

  #fileUpload {
    display: none;
    width: 100%;
  }

  .fileUploadContainer {
    display: flex;
    margin: 0;
    padding: 0;
    flex-direction: column;

    .pdf {
      width: 100%;
      height: 100%;
    }

    #uploadedImage {
      width: 193px;
      height: 129px;
      border-radius: 5px;
      background-color: #979797;
    }
  }

  .progressContainer {
    display: flex;
    align-items: center;
    margin-left: 1em;

    .times {
      width: 20px;
      height: 20px;
      border-radius: 3px;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1.5px solid #ff5656;
      color: #ff5656;
      font-size: 0.5em;
    }

    .progressText {
      font-family: "Graphik Regular";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #f7921c;
      margin-left: 0.5em;
      padding-top: 1em;
    }
  }

  .bordered {
    border: 1px solid#FF5656 !important;
  }

  .user__profile__data {
    input {
      padding: 5px !important;
    }
  }

  .edit_btn {
    color: #f7921c;
    font-size: 0.7em;
    border: none;
    outline: none;
    background: transparent;
  }

  //progress content

  .modal-body {
    min-height: 100%;
    padding: 2rem;
    // overflow: scroll;
  }

  .modal-body-intro {
    min-height: 200px;
    // background-color: yellow;
    margin: 0 auto;
  }

  .main-modal-content {
    // background-color: springgreen;
    min-width: 35%;
    max-width: max-content;
  }

  .modal-content {
    // background-color: red;
  }

  .main-form {
    // background-color: pink;
    display: flex;
    flex-direction: column;
  }

  .left-side-form {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .right-side-form {
    display: flex;
    flex-direction: column;
    width: 80%;
  }

  .inner {
    display: flex;
    gap: 2rem;
  }

  .contains-email {
    min-width: 94%;
  }

  .heard-main-container {
    min-width: 100%;
    max-width: 100%;
    width: min-content;
  }

  .bottom {
    margin: 0 auto;
    width: 100%;
  }

  @media screen and (max-width: 768px) and (max-width: 400px) {
    #update-information {
      width: 240px;
      height: 38px;
    }

    .modal {
      height: 98vh;
      overflow: scroll;
    }

    .left-side-form {
      display: flex;
      flex-direction: column;
      width: 90%;
    }

    .right-side-form {
      display: flex;
      flex-direction: column;
      width: 90%;
    }

    .inner {
      display: grid;
      gap: 1rem;
      max-height: 50vh;
      overflow-y: auto;
    }
    label {
      text-align: left !important;
    }
  }
</style>
