export const populartopics = [
  {
    id:'1',
    topic:'How to reset password',
    collapse: false,
    data: [
      'a. On the login page, click on the red inscription: Forgot your Password?',
      'b. On the Reset Password Page, enter Phone number',
      'c. Click on Reset',
      'd. New code is sent to you'
    ]
  },
  {
    id:'2',
    collapse: false,
    topic:'How to delete sender ID',
    data: [
      'a. On the overview page, locate sender IDs',
      'b. Scroll till you late the sender ID you want to delete',
      'c. Click on the bin icon near the SenderID to delete',
    ]
  },
  {
    id:'3',
    collapse: false,
    topic:'How to Topup',
    data: [
      'a. On the left menu on dashboard, click on Wallet',
      'b. Load money on wallet using preferred payment methods. Starts from 10 cedis and above',
      'c. Select beneath bundle to bundle sms and voice credit using wallet',
      'd. Click OK after selecting preferred bundle. Pleae select bundle whose value is lower than or equal to wallet balance'
    ]
  }
]